<template>
  <div>
    <table-wrapper
      :table-header="headers"
      noFilters
      :change-page-size="changePageSize"
      :fetch-data="fetchData"
      :pager="pager"
      :rows="rows"
      :list-length="list.length"
      :is-loading="isLoading"
    >
      <template #body>
        <transaction-row
          v-for="item in list"
          :key="item.transactionId"
          :item="item"
          statuses="walletTransaction"
        />
      </template>
      <template #noResults>
        <div>
          <loader-info v-if="isLoading" />
          <div v-else>
            <p class="mb-4">
              <em>
                <strong>
                  {{listMessageNoElements}}
                </strong>
              </em>
            </p>
          </div>
        </div>
      </template>
      <template #actions>
        <b-button
          variant="warning"
          size="sm"
          @click="downloadList"
        >
          Scarica elenco
        </b-button>
      </template>
    </table-wrapper>
  </div>
</template>

<script>
import { extractErrorMessage, isNotEmpty, isNotExist } from '@/utils/validators';

const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const TransactionRow = () => import('./TransactionRow.vue');
const TableWrapper = () => import('@/components/tableWrapper/TableWrapper.vue');

export default {
  name: 'WalletTransactionTable',
  components: { TransactionRow, TableWrapper, LoaderInfo },
  props: {
    entityType: String,
    entityId: String,
    withResponseOnly: Boolean,
    isLoading: Boolean,
  },
  data() {
    return {
      searchBase: {
        search: '',
        page: 0,
        size: 10,
        sort: [{ field: 'operationDate', direction: 'DESC' }],
        filters: [],
      },
      submitted: false,
      customFilters: false,
    };
  },
  computed: {
    list() {
      // console.log('list', this.$store.getters['wallet/userTransactionList']);
      return this.$store.getters['wallet/userTransactionList'];
    },
    hasList() {
      return this.$store.getters['wallet/userTransactionList'].length > 0;
    },
    pager() {
      return this.$store.getters['analysis/userAnalysisPageable'];
    },
    rows() {
      const paging = this.pager;
      return paging.totalElements;
    },
    listMessageNoElements() {
      return 'Nessuna transazione disponibile';
    },
    headers() {
      return [
        {
          classes: 'table-cell--compact',
          content: 'Stato',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Data',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Descrizione',
        },
        {
          classes: 'table-cell--compact text-right',
          content: 'Importo',
        },
      ];
    },
  },
  mounted() {
    // console.log('list created');
    if (this.withResponseOnly) {
      this.searchBase.filters.push(
        {
          field: 'status',
          operation: 'EQ',
          value: 'CLOSE',
        },
      );
    }
    this.fetchData(true);
  },
  methods: {
    fetchData(reload, nextPage) {
      // console.log('list fectad', this.searchBase, nextPage);
      // const paging = this.$store.getters['subject/investmentPageable'];
      // const search = { ...this.searchBase, ...paging };
      const search = { ...this.searchBase };
      // console.log('fetch', nextPage);
      if (isNotEmpty(nextPage)) {
        const paging = this.$store.getters['wallet/userTransactionListPageable'];
        if (nextPage > 0 && nextPage <= paging.totalPages) {
          search.page = nextPage - 1;
        }
      }
      const currentEntityType = this.entityType;
      const currentEntityId = this.entityId;
      this.$store.dispatch('wallet/transactionsList', {
        entityType: currentEntityType, entityId: currentEntityId, search, reset: reload,
      })
        .then(() => {
          this.submitted = false;
          this.searchBase.page = search.page;
        },
        (error) => {
          this.message = extractErrorMessage(error);
          this.submitted = false;
        });
    },
    changePageSize(size) {
      // console.log('pageSizeSelected', this.pageSizeSelected, size);
      this.searchBase.size = size;
      this.customFilters = true;
      this.fetchData(true);
    },
    downloadList() {
      if (isNotExist(this.entityId)) return;

      const search = { ...this.searchBase };
      this.$store.dispatch('wallet/exportTransactionsList', {
        entityId: this.entityId,
        entityType: this.entityType,
        search,
      });
    },
  },
};
</script>

<style scoped>

</style>
